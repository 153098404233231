#hero {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(./hero.jpg);
    background-position: 50% 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-size: cover;
    gap: 5%;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

#hero h1 {
    font-size: 48px;
    font-weight: 400;
    margin-top: 0
}

#hero h2 {
    font-size: 24px;
    font-weight: 400;
    color: rgb(244, 203, 8);
    margin-top: 70px;

}

#hero h2 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end) infinite alternate-reverse,
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 53% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}



#hero-content {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 5%;
    padding-bottom: 10px;
}

#hero-content p {
    padding: 10px 0;
}


#hero-buttons {
    display: flex;
    gap: 5%;
}

#avatar img{
    border-radius: 100%;
    height: 200px;
}


@media only screen and (max-width: 600px) {

    #hero-content {
        align-items: center;
        text-align: center;
    }

    #avatar {
        display: none;
    }

    @keyframes typing {
        from { width: 0 }
        to { width: 190% }
      }
  }