.head{
    background-color: #2c2c2c;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    width: 100%;
}

.head h2 {
    color: white;
}


.head h2 {
    margin-top: 0;
}

@media only screen and (max-width: 600px) {
    .head h2 {
        text-align: center;
        margin: 0 30px;
    }
  }
