#proj-flip {
    background-color: transparent;
    width: 300px;
    height: 230px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
#proj-flip:hover .flip-card-inner {
    transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    background-color: #2c2c2c;
    color: black;

}

.flip-card-front img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
/* Style the back side */
.flip-card-back {
    background-color: #2c2c2c;
    color: white;
    transform: rotateY(180deg);
    padding: 0 15px;
}
