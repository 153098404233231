#skill-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 30%;
    text-align: center;
    justify-content: center;
}

#skill-col h4 {
    margin-bottom: 0;
}

