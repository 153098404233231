#welcome {
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: auto;
    overflow-x: hidden; /* Hide the horizontal scrollbar */
    padding-top: 50px;
}

.left {
    flex: 1; /* Use flex to distribute remaining space evenly */
    padding-left: 100px;
    padding-right: 50px;
    box-sizing: border-box; /* Include padding in the element's width calculation */
}

.right {
    flex: 1; /* Use flex to distribute remaining space evenly */
    padding-right: 100px;
    padding-left: 50px;
    box-sizing: border-box; /* Include padding in the element's width calculation */
    padding-bottom: 10px;
}


.left p {
    font-style: italic;
    font-size: 24px;
    color: rgb(179, 179, 179);
}

#welcome-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

@media only screen and (max-width: 900px) {
    #welcome {
        flex-direction: column;
        text-align: center;
    }

    #welcome-border {
        width: 100%;
    }

    .right {
        padding-right: 50px;
    }

    .left {
        padding: 0;
    }

    #welcome #border {
        width: auto;
    }

  }
