#primary-btn {
    cursor: pointer;
    background-color: #f4cb08;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    border: solid 3px transparent;
    outline: 3px solid transparent;
    outline-offset: 0; /* Set the initial outline offset to 0 */
    transition: outline-offset 0.3s ease, outline-color 0.3s ease; /* Add both transitions */
}

#primary-btn:hover {
    background-color: transparent;
    color: #f4cb08;
    outline-offset: 5px; /* Increase the outline offset on hover */
    outline-color: #f4cb08; /* Change the outline color on hover */
}

#secondary-btn {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: solid 3px white;
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
    outline: 3px solid transparent;
    outline-offset: 0; /* Set the initial outline offset to 0 */
    transition: outline-offset 0.3s ease, outline-color 0.3s ease; /* Add both transitions */
}

#secondary-btn:hover {
    background-color: white;
    color: black;
    outline-offset: 5px; /* Increase the outline offset on hover */
    outline-color: #f4cb08; /* Change the outline color on hover */
}
  

/* #secondary-btn {
    cursor: pointer;
    background-color: transparent;
    color: white;
    border: solid 3px white;
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 700;
}

#secondary-btn:hover {
    background-color: white;
    color: black;
} */