#impressum {
    background-color: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 50px;
    position: relative;
}

#impressum a {
    text-decoration: none;
    color: white;
}

#impressum a:hover {
    text-decoration: underline;
}