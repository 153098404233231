#contact {
    background-color: #f4cb08;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    height: 200px;
    position: relative;
}

#contact button {
    background-color: #f4cb08 !important;
    color: white !important;
}

@media only screen and (max-width: 600px) {
    #contact {
        padding: 0 50px;
        flex-direction: column;
    }
  }