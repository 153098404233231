#skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}


#skills-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5%;
    width: 50%;
    margin: auto;
}

ul {
    list-style: none;
    padding-inline-start: 0px;
}

#skills-block ul li:before {
content: '✓ ';
}

input {
    margin-top: 30px;
    border-radius: 5px;
    min-width: 10%;
    border-style: solid;
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    #skills-block  {
        width: 80%;
    }    

}
