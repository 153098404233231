#navbar {
    background-color: #2c2c2c;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    justify-content: space-between;
    padding: 0 10%;
    gap: 10px
}

#navbar a {
    text-decoration: none;
    color: white;
}

#navbar a:hover {
    text-decoration: underline;
}

#navbar ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    gap: 5%;
}


#navbar li{
    list-style: none;
    margin: 5px 0;
}

@media only screen and (max-width: 600px) {
    #navbar span {
        display: none;
    }

    #navbar {
        justify-content: center;
    }
}

@media only screen and (max-width: 450px) {
    #navbar ul {
        flex-wrap: wrap;
    }
}
  