#tools-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2%;
    height: 140px;
    width: 80%;
    margin: 25px auto;
}

/* Define a keyframe animation called 'scaleImage' */
@keyframes scaleImage {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.5);
    }
  }
  
  
  #tools-block img {
    transform: scale(1); /* Start with the original size */
    transition: transform 0.3s ease; /* Add a transition for a smooth effect */
  }
  
  #tools-block img:hover {
    transform: scale(1.5); /* Scale to 1.5 times the original size on hover */
  }