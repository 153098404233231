* , *:before, *:after{ 
  box-sizing:border-box; 
  -moz-box-sizing:border-box; 
  -webkit-box-sizing:border-box; 
  -ms-box-sizing:border-box;
}

body {
  margin: 0;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'CustomPoppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'CustomPoppins';
  src: local('CustomPoppins'), 
    url('./font/Roboto-Regular.ttf');
}
