h3 {
  color: rgb(244, 203, 8);
  font-weight: 700;
  font-size: 24px;
}

h2 {
  font-weight: 900;
  font-size: 36px;
}

#border {
  border-color: #f4cb08;
  width: 80px;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 0;
  border-width: 3px;
  border-style: solid;
}

#imprint-section {
  background-color: #2c2c2c;
}

#imprint-section a{
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;
  text-decoration: none;
  color: white;
}

#imprint-section a:hover{
  text-decoration: underline;
  cursor: pointer;
}